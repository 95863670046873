.card:hover {
    box-shadow: 5px 10px 20px 1px rgba(0, 0, 0, 0.75) !important;
    transition: all 0.7s linear;
    transform: scale(1.04);
}

.card-body{
    padding: 0.4rem 0 !important;
}

.card-text{
    padding: 0.3rem 1rem;
    font-size: 1rem;
}

.overflow{
    overflow: hidden;
}

.card-img-top{
    transition: all 0.4s;
}



.card-body{
    margin-left: 0.2rem;
    margin-right: 0.2rem;
    text-align: justify;
    padding: 0 0;
}

.card-punctuacion-total {
  margin: 5px;
    font-size: 16px;
    width: 50px;
    height: 60px;
    margin-left: 13%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    border-radius: 4px;
    box-shadow: 0 0 1px hsl(0deg 0% 0% / 0.075), 0 0 2px hsl(0deg 0% 0% / 0.075),
      0 0 4px hsl(0deg 0% 0% / 0.075), 0 0 8px hsl(0deg 0% 0% / 0.075),
      0 0 16px hsl(0deg 0% 0% / 0.075);
  }


  .card-punctuacion-message {
    margin: 5px;
    padding: 15px;
    font-size: 25px;
    width: 400px;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    box-shadow: 0 0 1px hsl(0deg 0% 0% / 0.075), 0 0 2px hsl(0deg 0% 0% / 0.075),
      0 0 4px hsl(0deg 0% 0% / 0.075), 0 0 8px hsl(0deg 0% 0% / 0.075),
      0 0 16px hsl(0deg 0% 0% / 0.075);
  }
  
  .card-punctuacion-container {
    position: relative;
  }
  
  .card-punctuacion-container .card-punctuacion{
    display: flex;
    justify-content: center;
  }
  .splash-punctuacion {
    position: absolute;
    bottom: 0;
    left: 37%;
    right: 0;
    top: 0;
    clip-path: path(
      "M 0 303.5 C 0 292.454 8.995 285.101 20 283.5 L 460 219.5 C 470.085 218.033 480 228.454 480 239.5 L 500 430 C 500 441.046 491.046 450 480 450 L 20 450 C 8.954 450 0 441.046 0 430 Z"
    );
  }

/**/
resultados-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  background: #f0f4f8;
}

.resultados-container {
  text-align: center;
  background: #ffffff;
  border-radius: 12px;
  padding: 30px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
  width: 100%;
  position: relative;
}

.card-punctuacion {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.emoji-container {
  font-size: 6rem; /* Tamaño del emoji */
  margin-bottom: 15px;
  animation: bounce 1s infinite;
}

.nota-container {
  margin-top: 10px;
  font-size: 3rem;
  font-weight: bold;
  color: #2d3748;
}

.resultados-message {
  margin-top: 15px;
  font-size: 1.2rem;
  color: #4a5568;
}

.lectura-sugerida {
  width: 100%;
  background: #ffffff;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.lectura-sugerida h2 {
  margin-bottom: 20px;
  font-size: 1.5rem;
  color: #2b6cb0;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}
/**/  
  #progress .indicator {
    stroke: var(--accent);
  }
  
  ul-reel {
    display: flex;
    list-style: none;
    height: 100px;
    overflow-x: scroll;
    padding: 30px 0;
    flex: 0 0 800px;
    margin: 0 auto;
  }
  
  ::-webkit-scrollbar {
    height: 25px;
    width: 25px;
    background: #fff3;
  }
  
  ::-webkit-scrollbar-thumb {
    background: var(--accent);
    -webkit-border-radius: 1ex;
  }
  
  ::-webkit-scrollbar-corner {
    background: #fff3;
  }

  /**/
  .separatornh {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
  }
  
  .separatornh-line {
    flex-grow: 1;
    height: 3px;
    background-color: #ccc;
    border: none;
  }
  
  .separatornh-text {
    padding: 0 10px;
    color: #555;
    font-weight: bold;
    font-size: 1.2rem;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
  