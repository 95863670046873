
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  --black: #000000;
  --ash-black: #222;
  --white: #fafafa;
  --sky: #00ccff;
  --green: #22dddd;
  --blue: #1300ff;
  --dusk: #6600ff;
  --purple: #9900ff;
  --pink: #ff0066;
  --red: #fe0222;
  --orange: #fd7702;
  --yellow: #ffbb00;

  --background: var(--white);
  --accent: var(--blue);

  margin: 0;
  padding: 0;
  background-color: var(--background);
  color: var(--accent);
  padding-bottom: 100px;
}

.lectura-container {
  margin: 4rem;
}

.resultados-numero-container {
  margin: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
}



h1,
h2,
h3 {
  font-family: sofia-pro, sans-serif;
  font-weight: 600;
  font-style: normal;
}

h1 {
  font-size: 36px;
  font-weight: 700;
  letter-spacing: -1px;
  line-height: 1.2;
  text-align: center;
  margin: 100px 0 40px;
}

h2 {
  font-weight: 400;
  margin: 50px 0 10px;
  text-align: center;
}

h3,i {
  font-weight: 200;
  text-align: center;
  margin: 50px 0 10px;
}

p {
  margin: 0 0 30px 0;
  font-size: 18px;
  text-align: justify;
}



.card{
  background: white;
  border-radius: 30px;
  box-shadow: 0 0 1px hsl(0deg 0% 0% / 0.075), 0 0 2px hsl(0deg 0% 0% / 0.075),
    0 0 4px hsl(0deg 0% 0% / 0.075), 0 0 8px hsl(0deg 0% 0% / 0.075),
    0 0 16px hsl(0deg 0% 0% / 0.075);
  transform-origin: 10% 60%;
}


/* 
sadas */

body {
   background: linear-gradient(180deg, #fff6ed 0%, rgba(255, 221, 0, 0.8) 100%); 
  /* background: linear-gradient(180deg, #a6da1a 0%, rgba(60, 247, 206, 0.8) 100%); */
}

nav {
  top: 0;
  left: 0;
  bottom: 0;
  width: 220px;
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 245px;
  background: #fff;
  z-index: 50;
}

button {
  outline: none;
  border: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  cursor: pointer;
  position: absolute;
  top: 18px;
  left: 15px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: transparent;
}

.calendarclosebutton {
  outline: none;
  border: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  cursor: pointer;
  position: absolute;
  top: 18px;
  right: 0px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.8); /* Fondo semitransparente */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Sombra suave */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px; /* Tamaño del icono */
  color: #4b5563; /* Color del icono (gris) */
  transition: background 0.3s, box-shadow 0.3s; /* Transición suave */
}

.calendarclosebutton:hover {
  background: rgba(255, 255, 255, 1); /* Fondo más sólido al hacer hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Sombra más pronunciada al hacer hover */
}

.calendarclosebutton:active {
  background: rgba(255, 255, 255, 0.9); /* Cambio de color al hacer clic */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Sombra más pequeña al hacer clic */
}
/* Estilo específico para el botón */
.btn-custom {
  position: relative; /* Asegúrate de que no se superponga con otros elementos */
  background-color: #48bb78; /* Verde claro */
  color: white; /* Texto blanco */
  border-radius: 0.375rem; /* Bordes redondeados */
  padding: 0.5rem 1rem; /* Padding */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Sombra */
  font-weight: 600; /* Peso de fuente */
  display: inline-block; /* Alineación del botón */
  cursor: pointer; /* Cursor de puntero */
  transition: background-color 0.3s ease, transform 0.3s ease; /* Transición suave */
}

.btn-custom:hover {
  background-color: #2f855a; /* Verde oscuro al pasar el cursor */
  transform: scale(1.05); /* Aumento del tamaño */
}


ul,
li {
  margin: 0;
  padding: 0;
}

ul {
  padding: 0;
  position: absolute;
  top: 70px;
  width: 235px;
}

li {
  list-style: none;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.icon-placeholder {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  flex: 40px 0;
  margin-right: 15px;
}

.text-placeholder {
  border-radius: 5px;
  width: 200px;
  height: 25px;
  padding-left: 10px;
  flex: 1;
}


footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
  background-image: radial-gradient(
    rgba(0, 0, 0, 0) 1px,
    var(--background) 1px
  );
  background-size: 4px 4px;
  backdrop-filter: blur(3px);
  font-size: 14px;
  line-height: 14px;
}

footer::before {
  display: block;
  content: "";
  position: absolute;
  top: -1px;
  left: 0;
  right: 0;
  height: 1px;
  background: var(--accent);
  opacity: 0.2;
}

footer svg {
  margin-right: 20px;
}

footer a {
  text-decoration: none;
  color: var(--accent);
}

code {
  font-family: input-mono, monospace;
  font-weight: 400;
  font-style: normal;
}

.progress-bar-lecturas {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 30px;
  background-color: #FF008C;
  transform-origin: 0%;
  z-index: 2;
}


/* Estilos para el contenedor del PDF */
.pdf-container {
  width: 100%;
  max-width: 800px; /* Limita el ancho máximo */
  margin: 0 auto;   /* Centra el contenedor */
}

/* Estilos para el canvas */
canvas {
  width: 100%;
  height: auto;
  border: 1px solid black;
}

/* styles.css */
.speedometer-container canvas {
  display: block; /* Evita espacios adicionales */
  margin: 0; /* Elimina márgenes */
  padding: 0; /* Elimina padding */
  width: 100%; /* Asegura que ocupe todo el ancho */
  height: 100%; /* Asegura que ocupe todo el alto */
}


/* Estilos para el contenedor de los botones y el texto */
.pdf-controls {
  display: flex;          /* Usa flexbox para alinear los elementos */
  align-items: center;    /* Centra verticalmente los elementos */
  gap: 10px;              /* Espacio entre los elementos */
  margin-top: 10px;       /* Margen superior para separar del canvas */
  justify-content: center; /* Centra los elementos horizontalmente */
}

/* Estilos específicos para los botones del PDFViewer */
.pdf-button {
  padding: 8px 16px !important;      /* Espaciado interno */
  font-size: 14px !important;        /* Tamaño de la fuente */
  cursor: pointer !important;        /* Cambia el cursor al pasar sobre el botón */
  border: 1px solid #ccc !important; /* Borde del botón */
  background-color: #f9f9f9 !important; /* Color de fondo */
  border-radius: 4px !important;     /* Bordes redondeados */
  margin: 0 !important;              /* Elimina márgenes predeterminados */
  position: static !important;       /* Restablece el posicionamiento */
  top: auto !important;              /* Restablece la posición vertical */
  left: auto !important;             /* Restablece la posición horizontal */
  width: auto !important;            /* Restablece el ancho */
  height: auto !important;           /* Restablece la altura */
}

/* Estilos para los botones deshabilitados */
.pdf-button:disabled {
  background-color: #e0e0e0 !important; /* Color de fondo cuando está deshabilitado */
  cursor: not-allowed !important;       /* Cambia el cursor cuando está deshabilitado */
}

/* Estilos para el texto de la página */
.pdf-controls p {
  margin: 0;                /* Elimina el margen predeterminado */
  font-size: 14px;          /* Tamaño de la fuente */
}